import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Typography, Tooltip, Button } from '@mui/material';

import { DashboardContext } from '../../context/DashboardContext';
import { TenantContext } from '../../context/TenantContext';
import Loader from '../shared/Loader';
import './PreElecChecks.css';
import '../../styles.css';
import {
  cnlrangeresults,
  startStateMachine,
  getcnlresultfile,
  GetStateMachineStatus,
} from '../../services/api/dashboard';
import { DropDown } from '../shared/DropDown';
import { CnlCheckResults } from '../../entities/CnlResults';
import { S3FileObject } from '../../entities/S3FileObject';
import CnlCheckerResultsTable from './results/resultsTable';
import { StepFunctionResp } from '../../entities/StepFunctionResp';

const PreElecChecks: React.FC = () => {
  let pollInterval: NodeJS.Timeout;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [filesList, setFilesList] = useState<string[]>([]);
  const [s3filesList, setFullS3FilesList] = useState<S3FileObject[]>([]);
  const [selectedFileValue, setSelectedFileValue] = useState('');
  const [s3fileContents, setS3FilesContents] = useState<CnlCheckResults>();
  const [sfArn, setSfArn] = useState('');
  const [jobStatus, setJobStatus] = useState<string | null>(null);
  const [pollingTimeout, setPollingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const {
    tenants,
    selectedTenant,
    setTenant,
    electionsForTenant,
    selectedElection,
    setElection,
  } = useContext(TenantContext);

  const { setupComplete, filteredStations, pollstations, fetching } =
    useContext(DashboardContext);

  const tenantNames: string[] = useMemo(() => {
    return ['', ...tenants];
  }, [tenants]);

  const headers = ['Station', 'Ballot / CNL info'];

  const getCnlResultsFiles = async (
    selectedElection: string,
    selectedTenant: string
  ) => {
    try {
      if (selectedTenant && selectedElection) {
        setDisabled(false);
        //call to get the s3 data
        let results = await cnlrangeresults(
          selectedElection.replaceAll('-', '_'),
          selectedTenant
        );
        results = results.sort((a: S3FileObject, b: S3FileObject) =>
          b.LastModified.localeCompare(a.LastModified)
        );
        //set the setFullS3FilesList so we have the full s3 data in state
        setFullS3FilesList(results);
        //set the filesList so we can display the file names in the drop down
        setFilesList(
          results
            .map((z) => z.Key)
            .map((x) => x.split(`${selectedTenant}/${selectedElection}/`)[1])
        );
        //return the results
        return results;
        // Do something with the files
      }
    } catch (error) {
      // Handle error
      console.log('error', error);
    }
  };

  useEffect(() => {
    getFileResults(
      selectedElection,
      selectedTenant,
      getCnlResultsFiles,
      setSelectedFileValue,
      filesList
    );
  }, [selectedElection, selectedTenant]);

  const fetchJobStatus = async () => {
    const params = {
      executionArn: sfArn,
    };

    if (sfArn && sfArn.length > 0) {
      try {
        console.log(`getting state machine status for -> ${sfArn}`);
        const status = await GetStateMachineStatus(params);
        console.log('status', status);
        setJobStatus(status);
        if (status !== 'SUCCEEDED' && status !== 'FAILED') {
          setDisabled(true);
          setPollingTimeout(() => setTimeout(fetchJobStatus, 15000)); // Poll every 15 seconds
        } else {
          setDisabled(false);
          setJobStatus(null);
          getFileResults(
            selectedElection,
            selectedTenant,
            getCnlResultsFiles,
            setSelectedFileValue,
            filesList
          );
        }
      } catch (error) {
        console.error('Error fetching job status:', error);
        setDisabled(false);
        setJobStatus(null);
      }
    }
  };

  useEffect(() => {
    fetchJobStatus();

    // Clear timeout when component unmounts
    return () => {
      if (pollingTimeout) {
        clearTimeout(pollingTimeout);
        setSfArn('');
        setDisabled(false);
        setJobStatus(null);
      }
    };
  }, [sfArn]);

  // Stop polling if navigating away
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (pollingTimeout) {
        clearTimeout(pollingTimeout);
        setSfArn('');
        setDisabled(false);
        setJobStatus(null);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [pollingTimeout]);

  let message = null;
  if (!setupComplete) {
    if (!selectedTenant) {
      message = <Typography variant="body1">{'Select Tenant'}</Typography>;
    }
    if (selectedTenant && !selectedElection) {
      message = (
        <Typography variant="body1">{'Select Election Date'}</Typography>
      );
    }
    <Typography variant="body1">{'Select Election Date'}</Typography>;
    if (fetching) {
      message = <Loader message={'Fetching report data'} />;
    }
  }

  const getCnlResults = async (value: string) => {
    setSelectedFileValue(value);
    const currentFileList = s3filesList;
    if (currentFileList && currentFileList.length > 0) {
      const currentFile = currentFileList.filter((file) =>
        file.Key.includes(value)
      );
      const fileContents = await getcnlresultfile(currentFile[0].Key);
      setS3FilesContents(fileContents);
    }
  };

  const onGetPreElecCheckResults = async () => {
    setDisabled(true);
    const stationNumbers = filteredStations.map((z) => z.number);
    const overall = [];
    const stationIds = [];
    for (let index = 0; index < stationNumbers.length; index++) {
      const element = stationNumbers[index];
      const stationInfo = pollstations.filter((z) => z.number === element);
      const ballots = Object.keys(stationInfo[0].ballots);
      // stationIds.push(stationInfo[0].pollStationId);
      stationIds.push({
        stationNumber: stationInfo[0].pollStationId,
        tenant: stationInfo[0].organisation,
        stationNo: element,
      });

      for (let index = 0; index < ballots.length; index++) {
        const ballot = ballots[index];
        const ballotAreas = [stationInfo[0].ballots[ballot]];
        const areaTotals = [];
        for (let index = 0; index < ballotAreas.length; index++) {
          const area = ballotAreas[index];
          const areaKey = Object.keys(area);
          const totalBallots = [];
          for (let index = 0; index < areaKey.length; index++) {
            const key = areaKey[index];
            totalBallots.push({
              [area[key].bref]: {
                from: area[key].ordinary.numbers[0].from,
                to: area[key].ordinary.numbers[0].to,
                uim: area[key].ordinary.numbers[0].uimPrefix,
                bref: area[key].bref,
                area: key,
              },
            });
          }
          overall.push({ [stationInfo[0].pollStationId]: totalBallots });
        }
      }
    }
    const groupedData = Object.entries(
      overall.reduce((acc, item) => {
        const key = Object.keys(item)[0];
        return { ...acc, [key]: [...(acc[key] || []), ...item[key]] };
      }, {})
    ).map(([key, value]) => ({ [key]: value }));
    const variables = {
      tenant: selectedTenant as string,
      electionDate: selectedElection as string,
      params: groupedData as object,
      stationIds: stationIds as [],
    };
    const stateMachineRun = await startStateMachine(variables);
    console.log(`stateMachineRun >> ${JSON.stringify(stateMachineRun)}`);
    if (stateMachineRun) {
      setSfArn(stateMachineRun.executionArn);
    }
  };

  const UIRows = useMemo(() => {
    if (s3fileContents) {
      return Object.values(s3fileContents);
    } else {
      return [];
    }
  }, [s3fileContents]);

  return (
    <>
      <div className="filterBar">
        <DropDown
          options={tenantNames}
          name="Tenant"
          label="Tenant"
          value={selectedTenant}
          // onSelect={(value) => setTenant(value)}
          onSelect={(value) => {
            setTenant(value);
            setFullS3FilesList([]);
            setS3FilesContents(undefined);
            setSelectedFileValue('');
          }}
        />
        <DropDown
          options={electionsForTenant}
          name="Election Date"
          label="Election Date"
          value={selectedElection}
          onSelect={(value) => {
            setElection(value);
            setFullS3FilesList([]);
            setS3FilesContents(undefined);
            setSelectedFileValue('');
          }}
        />
        <DropDown
          className="select-control"
          options={filesList}
          name="Report Date"
          label="Report Date"
          value={selectedFileValue}
          onSelect={(value: any) => getCnlResults(value)}
        />
      </div>
      <div className="stats-totals">
        <Tooltip title={'Run Pre-Election CNL Checks'}>
          <Button
            disabled={disabled}
            id="RunCnlChecksBtn"
            color="primary"
            variant="contained"
            onClick={onGetPreElecCheckResults}
          >
            Run Checks
          </Button>
        </Tooltip>
        <div>{jobStatus && <p>Job Status: {jobStatus}</p>}</div>
      </div>
      <br></br>
      <div>
        <div className="stats-section">
          <div>
            <Typography variant={'h5'}>Results</Typography>
          </div>
          <br></br>
          {Object.entries(UIRows).length ? (
            <div className="stats-content">
              {CnlCheckerResultsTable(headers, UIRows)}
            </div>
          ) : (
            <div className="no-data">
              <Typography variant={'h6'} paragraph align="center" gutterBottom>
                No Data
              </Typography>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(PreElecChecks);
function getFileResults(
  selectedElection: string | undefined,
  selectedTenant: string | undefined,
  getCnlResultsFiles: (
    selectedElection: string,
    selectedTenant: string
  ) => Promise<S3FileObject[] | undefined>,
  setSelectedFileValue: React.Dispatch<React.SetStateAction<string>>,
  filesList: string[]
) {
  try {
    const election = selectedElection as string;
    const tenant = selectedTenant as string;
    if (election && tenant) {
      const currentFiles = getCnlResultsFiles(
        election.replaceAll('-', '_'),
        tenant
      );
      setSelectedFileValue(filesList[0]);
    }
  } catch (error) {
    console.log('error', error);
  }
}
