import React, { useContext, useMemo } from 'react';

import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  Typography,
  Checkbox,
} from '@mui/material';

import { DashboardContext } from '../../context/DashboardContext';
import { TenantContext } from '../../context/TenantContext';
import LastFetchTime from '../shared/LastFetchTime';
import Loader from '../shared/Loader';
import AggregateStatistics from './AggregateStatistics';
import RecalculateStats from '../shared//RecalculateStats';
import PollstationFilterBar from './Filter/PollstationFilterBar';
import PollstationRow from './TableRow/PollstationItemRow';
import { sortAlphaNumeric } from '../../utils/utils';
import './PollstationsList.css';
import '../../styles.css';
import { recalculateStats } from '../../services/api/dashboard';

const PollstationsList: React.FC = () => {
  const { selectedTenant, selectedElection } = useContext(TenantContext);
  const {
    dashError,
    setupComplete,
    lastFetch,
    filteredStations,
    fetching,
    allStationsChecked,
    checkAllStations,
    refreshDashboard,
  } = useContext(DashboardContext);

  const pollstationRowItems = useMemo(
    () =>
      filteredStations
        .sort((a, b) => sortAlphaNumeric(a.number, b.number))
        .map((pollstation) => (
          <PollstationRow
            key={`PS_ROW_${pollstation.organisation}-${pollstation.name}-${pollstation.number}-${pollstation.electionDate}`}
            pollstation={pollstation}
          />
        )),
    [filteredStations]
  );

  let message = null;
  if (!setupComplete) {
    if (!selectedTenant) {
      message = <Typography variant="body1">{'Select Tenant'}</Typography>;
    }
    if (selectedTenant && !selectedElection) {
      message = (
        <Typography variant="body1">{'Select Election Date'}</Typography>
      );
    }
    if (fetching) {
      message = <Loader message={'Fetching pollstation report data'} />;
    }
  }
  if (dashError.length) {
    message = <Typography variant="body1">{`${dashError}`}</Typography>;
  }

  const checkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    checkAllStations(event.currentTarget.checked);
  };

  const onRecalculateStatsClick = async () => {
    console.log('clicked');
    if (selectedTenant && selectedElection) {
      const request = {
        tenant: selectedTenant,
        electionDate: selectedElection,
      };
      return await recalculateStats(request);
    }
  };

  return (
    <>
      <div>
        <LastFetchTime
          lastFetch={lastFetch}
          onRefreshClick={refreshDashboard}
          disabled={!selectedTenant || !selectedElection || fetching}
        />
        <RecalculateStats
          onRecalculateStatsClick={onRecalculateStatsClick}
          disabled={!selectedTenant || !selectedElection || fetching}
        />
        <PollstationFilterBar />
      </div>
      <div>
        <AggregateStatistics />
      </div>
      <Paper elevation={6} style={{ borderRadius: '10px' }}>
        <Table stickyHeader style={{ borderRadius: '10px' }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ width: '3%' }}
                padding={'checkbox'}
                align="center"
              >
                <Checkbox
                  color={'primary'}
                  checked={allStationsChecked}
                  onChange={checkChange}
                />
              </TableCell>
              <TableCell style={{ width: '17%' }}>Pollstation</TableCell>
              <TableCell style={{ width: '20%' }}>Staff</TableCell>
              <TableCell style={{ width: '30%' }}>Devices</TableCell>
              <TableCell style={{ width: '20%' }}>
                Ballot Paper Accounts
              </TableCell>
              <TableCell style={{ width: '5%' }} align="center">
                More
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {setupComplete ? (
              fetching ? (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <Loader message={'Fetching pollstation report data'} />
                  </TableCell>
                </TableRow>
              ) : dashError.length ? (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    {message}
                  </TableCell>
                </TableRow>
              ) : (
                pollstationRowItems
              )
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  {message}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default React.memo(PollstationsList);
