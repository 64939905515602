import React, { useEffect, useMemo, useState } from 'react';
import {
  Typography,
  Button,
  IconButton,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Paper,
  Tab,
  Tabs,
  CircularProgress,
  TableSortLabel,
} from '@mui/material';
import { AddCircle, CheckCircle, Close, GetApp } from '@mui/icons-material';
import { Pollstation } from '../../../entities/Pollstation';
import {
  downloadElectionReport,
  getLatestReports,
} from '../../../services/api/dashboard';
import { sortAlphaNumeric } from '../../../utils/utils';
import './TableRow.css';
import moment from 'moment';
import { Report } from '../../../entities/Files';
import TabPanel from '../../shared/TabPanel';
import { Buffer } from 'buffer';

export interface PollstationReportsProps {
  pollstation: Pollstation;
  onClose: () => void;
}
// eslint-disable-next-line
const fileTypeStrings: any = {
  markedregister: 'Marked Register',
  correspondingnumberlist: 'Corresponding Number List',
  markedproxyregister: 'Marked Proxy Register',
  analytics: 'Transaction Statistics',
  bpa: 'BPA Report',
  ballotpaperefusallist: 'Ballot Paper Refusal List',
  voteridstatistics: 'Voter ID Statistics',
  videf: 'Voter Identification Evaluation Form',
  sealed_report: 'Seal Audit Report',
  sealed: 'Sealed CNL and Marked Register Report',
  sealedaudit: 'Seal Audit Report',
  sealed_audit_report_amalgamated: 'Seal Audit Report (Amalgamated)',
};

// eslint-disable-next-line
const combinedFileTypeStrings: any = {
  MarkedRegister: 'markedregister',
  CorrespondingNumberList: 'correspondingnumberlist',
  ProxyRegister: 'markedproxyregister',
  BPA_Report: 'bpa',
  BallotPaperRefusalList: 'ballotpaperefusallist',
  VoterIDStatistics: 'voteridstatistics',
  VIDEF: 'videf',
  Sealed_Audit_Report: 'sealed_report',
  // Sealed_Audit_Report_Amalgamated: 'Seal Audit Report (Amalgamated)',
  Sealed_Audit_Report_Amalgamated: 'sealed_audit_report_amalgamated',
};

const PollstationReports = (props: PollstationReportsProps) => {
  const { pollstation, onClose } = props;

  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [reports, setReports] = useState<Report[]>([]);

  const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const getReports = async () => {
    try {
      setLoading(true);
      const combined = selectedTab === 1;
      const reports = await getLatestReports(
        pollstation.organisation,
        pollstation.electionDate,
        combined
      );
      const latestReports: Report[] = reports.map((report) => {
        const pathParts = report.file.split('/');
        const reportType = pathParts[2];

        const [fileType, electionName, station, , extention] =
          pathParts[3].split('|');
        const isCombined = reportType === 'combined';

        const fileName = isCombined
          ? fileTypeStrings[combinedFileTypeStrings[fileType]]
          : fileTypeStrings[reportType];

        return {
          created: report.updateTime,
          name: fileName,
          fileType: extention,
          electionName: electionName,
          station: station,
          path: report.file,
          combined: isCombined,
          fileName: `${pathParts[1]}-${reportType}${
            pathParts[3] === 'combined' ? '' : `-${reportType}`
          }.${extention}`,
          fileSize: `${formatBytes(report.size)}`,
          valid: report.valid,
        };
      });
      console.log(latestReports);
      setReports(latestReports);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error as any);
    } finally {
      setLoading(false);
    }
  };

  const downloadClick = async (path: string) => {
    try {
      const combined = selectedTab === 1;
      const { file } = await downloadElectionReport(path, combined);
      if (file) window.open(file);
    } catch (error) {
      console.log(error);
    }
  };

  const stationReports = useMemo(() => {
    if (reports) {
      return reports
        .filter((item) => {
          const fileMatch =
            // (item.station ===
            //   Buffer.from(pollstation.number, 'ascii').toString('hex') &&
            //   !item.combined) ||
            (item.station === pollstation.number && !item.combined) ||
            (item.fileType === 'BPA Report' && item.fileName.endsWith('.pdf'));
          if (fileMatch) return item;
        })
        .sort((a, b) => sortAlphaNumeric(a.station, b.station));
    } else {
      return [];
    }
  }, [reports]);

  const combinedReports = useMemo(() => {
    if (reports) {
      return reports
        .filter((item) => item.combined)
        .sort((a, b) => sortAlphaNumeric(a.station, b.station));
    } else {
      return [];
    }
  }, [reports]);

  useEffect(() => {
    getReports();
  }, [selectedTab, pollstation]);

  return (
    <div className="ps-reports">
      <div className="ps-reports-header">
        <Typography variant="h6">{`Station ${pollstation.number} Reports`}</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </div>
      <div className="ps-reports-content">
        <Tabs
          indicatorColor="primary"
          value={selectedTab}
          onChange={(_, newValue: number) => setSelectedTab(newValue)}
        >
          <Tab id="stationReports" label="Station Reports" />
          <Tab id="amalgamatedReports" label="Amalgamated Reports" />
        </Tabs>
        <TabPanel index={0} value={selectedTab}>
          <>
            {loading && <BasicLoader />}
            {!loading && stationReports.length > 0 && (
              <FileSection files={stationReports} download={downloadClick} />
            )}
          </>
        </TabPanel>
        <TabPanel index={1} value={selectedTab}>
          <>
            {loading && <BasicLoader />}
            {!loading && combinedReports.length > 0 && (
              <FileSection files={combinedReports} download={downloadClick} />
            )}
          </>
        </TabPanel>
      </div>
    </div>
  );
};

const BasicLoader = () => (
  <Paper className={'paper-style'} elevation={10}>
    <CircularProgress style={{ margin: '1rem auto' }} />
  </Paper>
);

interface FileSectionProps {
  files: Report[];
  download: (file: string) => void;
}
const FileSection: React.FC<FileSectionProps> = React.memo(
  (props: FileSectionProps) => {
    const { files, download } = props;

    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<string>('');

    // eslint-disable-next-line
    const style: any = {
      electionName: { width: '40%' },
      created: { width: '20%' },
      fileType: { width: '20%' },
      action: { width: '20%' },
      station: { width: '10%' },
    };

    const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: string
    ) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const sortedFiles = useMemo(() => {
      if (orderBy === 'valid') {
        return [...files].sort((a, b) => {
          if (order === 'asc') {
            return a.valid === b.valid ? 0 : a.valid ? -1 : 1;
          } else {
            return a.valid === b.valid ? 0 : a.valid ? 1 : -1;
          }
        });
      }
      return files;
    }, [order, orderBy, files]);

    return (
      <Paper elevation={10}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell id="electionType" style={style.fileType} align="left">
                File Name
              </TableCell>
              <TableCell
                id="electionName"
                style={style.electionName}
                align="left"
              >
                Election Name
              </TableCell>
              <TableCell
                id="electionCreated"
                style={style.created}
                align="left"
              >
                Created
              </TableCell>
              <TableCell id="fileType" style={style.created} align="left">
                Type
              </TableCell>
              <TableCell id="fileSize" style={style.created} align="left">
                Size
              </TableCell>
              <TableCell id="fileSize" style={style.created} align="left">
                <TableSortLabel
                  active={orderBy === 'valid'}
                  direction={orderBy === 'valid' ? order : 'asc'}
                  onClick={(e) => handleRequestSort(e, 'valid')}
                >
                  Valid
                </TableSortLabel>
              </TableCell>
              <TableCell
                id="electionAction"
                style={style.action}
                align="center"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedFiles.map((file, index) => (
              <TableRow key={`${file.fileName}-${index}`}>
                <TableCell style={style.fileType} align="left">
                  {file.name}
                </TableCell>
                <TableCell style={style.fileName} align="left">
                  {file.electionName}
                </TableCell>
                <TableCell style={style.created} align="left">
                  {moment(file.created).format('DD MMMM hh:mm A')}
                </TableCell>
                <TableCell style={style.fileType} align="left">
                  {file.fileType}
                </TableCell>
                <TableCell
                  style={style.fileType}
                  align="left"
                  id={`fileSize-${file.name}-${file.electionName}-${file.station}-${file.fileType}`}
                >
                  {file.fileSize}
                </TableCell>
                <TableCell>
                  {file.valid !== undefined &&
                    (file.valid ? (
                      <CheckCircle style={{ color: 'forestgreen' }} />
                    ) : (
                      <AddCircle
                        style={{ transform: 'rotate(45deg)', color: 'red' }}
                      />
                    ))}
                </TableCell>
                <TableCell style={style.action} align="center">
                  <Button
                    id={`downloadFileBtn-${file.name}-${file.electionName}`}
                    size="small"
                    onClick={() => download(file.path)}
                  >
                    <GetApp />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
);
FileSection.displayName = 'FileSection';

export default React.memo(PollstationReports);
