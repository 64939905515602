import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, MenuItem, Select, TextField, InputLabel } from '@mui/material';
import TenantPollstationPicker from '../../ElectionDaySupport/Modals/TenantPollstationPicker';
import { TenantPollstationContext } from '../../../../context/TenantPollstationContext';
import MDModal from '../../../shared/MDModal';
import '../../../shared/MDModal.css';
import { GenericModalProps } from '../../GenericModalProps';
import { certificateOfEmploymentElector } from '../../../../services/api/support';
import { f } from 'msw/lib/SetupApi-f4099ef3';

interface ElectorModalProps extends GenericModalProps {
  title: string;
}

const CreateCertificateOfEmploymentElector: React.FC<ElectorModalProps> = ({
  open,
  onClose,
  title,
}: ElectorModalProps) => {
  const {
    fetching,
    selectedTenant,
    selectedPollstation,
    selectedElectionDate,
  } = useContext(TenantPollstationContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    electorId: '',
    address1: '',
    address2: '',
    address3: '',
    postcode: '',
    areaName: [],
  });
  const [areaNames, setAreaNames] = useState(['']);

  const onSubmit = async () => {
    const data = {
      tenant: selectedTenant || '',
      stationId: selectedPollstation?.pollStationId || '',
      electionDate: selectedElectionDate || '',
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      electorId: formValues.electorId,
      address1: formValues.address1,
      address2: formValues.address2,
      address3: formValues.address3,
      postcode: formValues.postcode,
      areaName: formValues.areaName || '',
    };
    return Promise.resolve(setLoading(true))
      .then(() =>
        certificateOfEmploymentElector(data)
          .then(() => {
            setSuccess(true);
            resetvalues();
            setTimeout(() => setSuccess(false), 5000);
          })
          .catch((error) => {
            setSuccess(false);
            setErrorMessage(error.message);
            setTimeout(() => setErrorMessage(''), 5000);
          })
      )
      .then(() => setLoading(false));
  };

  const resetvalues = () => {
    setFormValues({
      firstName: '',
      lastName: '',
      electorId: '',
      address1: '',
      address2: '',
      address3: '',
      postcode: '',
      areaName: [],
    });
    setSubmitEnabled(false);
  };

  const capitaliseFirstLetter = (string: string) => {
    const parts = string.split(' ');
    for (let i = 0; i < parts.length; i++) {
      if (isNaN(Number(parts[i]))) {
        parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].slice(1);
        break;
      }
    }
    return parts.join(' ');
  };

  const validatePostcode = (postcode: string) => {
    const regex = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0AA)$/i;
    return regex.test(postcode.replace(/\s/g, ''));
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]:
        id === 'firstName' || id === 'lastName' || id.includes('address')
          ? capitaliseFirstLetter(value)
          : value.trim(),
    }));
  };

  const areaChange = (val: any) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      areaName: val || [],
    }));
  };

  const createMenuItem = (index: number, area: string): JSX.Element => {
    return (
      <MenuItem id={`${index}-${area}`} value={area}>
        {area}
      </MenuItem>
    );
  };

  useEffect(() => {
    setSubmitEnabled(
      Boolean(
        selectedTenant &&
          selectedPollstation &&
          formValues.areaName &&
          /^[A-Za-z](?:[A-Za-z0-9\\//&-]*[A-Za-z0-9])?\*?-[0-9]+(?:\.[0-9]{3}|\/[0-9]+)?$/.test(
            formValues.electorId
          ) &&
          validatePostcode(formValues.postcode)
      )
    );
  }, [selectedTenant, selectedPollstation, formValues]);

  const uniqueArray = (array: string[]) => {
    const newSet = new Set(array);
    return Array.from(newSet);
  };

  const electoralAreas = useMemo(() => {
    if (selectedPollstation) {
      const areas = selectedPollstation?.electoralAreas
        .replace(/[[\]']+/g, '')
        .split('|');

      const uniqueAreas = uniqueArray(areas);
      return uniqueAreas;
    } else {
      return [];
    }
  }, [selectedPollstation]);

  return (
    <MDModal
      open={open}
      onClose={onClose}
      title={title}
      height={750}
      loading={loading || fetching}
      errorMessage={errorMessage}
      success={success}
      content={
        <>
          <TenantPollstationPicker />
          <div style={{ margin: '2rem 0' }}>
            {selectedPollstation && (
              <TextField
                disabled={loading}
                id="firstName"
                label="First Name"
                variant="outlined"
                margin="normal"
                fullWidth
                value={formValues.firstName}
                onChange={handleInputChange}
              />
            )}
            {formValues.firstName && (
              <TextField
                disabled={loading}
                id="lastName"
                label="Last Name"
                variant="outlined"
                margin="normal"
                fullWidth
                value={formValues.lastName}
                onChange={handleInputChange}
              />
            )}
            {formValues.lastName && (
              <TextField
                disabled={loading}
                id="electorId"
                label="PD-ENO"
                variant="outlined"
                margin="normal"
                fullWidth
                value={formValues.electorId}
                onChange={handleInputChange}
                error={
                  !/^[A-Za-z](?:[A-Za-z0-9\\//&-]*[A-Za-z0-9])?\*?-[0-9]+(?:\.[0-9]{3}|\/[0-9]+)?$/.test(
                    formValues.electorId
                  ) && formValues.electorId !== ''
                }
                helperText={
                  !/^[A-Za-z](?:[A-Za-z0-9\\//&-]*[A-Za-z0-9])?\*?-[0-9]+(?:\.[0-9]{3}|\/[0-9]+)?$/.test(
                    formValues.electorId
                  ) && formValues.electorId !== ''
                    ? 'Invalid PD-ENO format'
                    : ''
                }
              />
            )}
            {formValues.electorId && (
              <TextField
                disabled={loading}
                id="address1"
                label="Address Line 1"
                variant="outlined"
                margin="normal"
                fullWidth
                value={formValues.address1}
                onChange={handleInputChange}
              />
            )}
            {formValues.address1 && (
              <TextField
                disabled={loading}
                id="address2"
                label="Address Line 2"
                variant="outlined"
                margin="normal"
                fullWidth
                value={formValues.address2}
                onChange={handleInputChange}
              />
            )}
            {formValues.address2 && (
              <TextField
                disabled={loading}
                id="address3"
                label="Address Line 3"
                variant="outlined"
                margin="normal"
                fullWidth
                value={formValues.address3}
                onChange={handleInputChange}
              />
            )}
            {formValues.address3 && (
              <TextField
                disabled={loading}
                id="postcode"
                label="Postcode"
                variant="outlined"
                margin="normal"
                fullWidth
                value={formValues.postcode}
                onChange={handleInputChange}
                error={
                  !validatePostcode(formValues.postcode) &&
                  formValues.postcode !== ''
                }
                helperText={
                  !validatePostcode(formValues.postcode) &&
                  formValues.postcode !== ''
                    ? 'Invalid postcode'
                    : ''
                }
              />
            )}
            {formValues.postcode && (
              <>
                <InputLabel id="areaName-label">Area Names</InputLabel>
                <Select
                  labelId="areaName-label"
                  id="areaName"
                  value={formValues.areaName}
                  fullWidth
                  multiple
                  displayEmpty
                  onChange={(e) => areaChange(e.target.value)}
                >
                  {electoralAreas.map((area, index) =>
                    createMenuItem(index, area)
                  )}
                </Select>
              </>
            )}
          </div>
        </>
      }
      actionBar={
        <Button
          disabled={!submitEnabled}
          color="primary"
          variant="contained"
          size="medium"
          fullWidth
          onClick={onSubmit}
        >
          Run Task
        </Button>
      }
    />
  );
};

export default CreateCertificateOfEmploymentElector;
