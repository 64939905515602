import React, { useContext, useState } from 'react';
import { Paper, Table, TableBody, TableRow, Typography } from '@mui/material';
import { TenantContext } from '../../context/TenantContext';
import { downloadDataValidationResult } from '../../services/api/dashboard';
import { DownloadQueryViewRequest } from '../../entities/Support';
import QueryResult from './QueryResult';
import { InternalValidationReport } from '../../entities/Validations';
import './validation.css';

interface ValidationFindingsProps {
  data: InternalValidationReport[];
}
export interface Columns {
  name: string;
  accessor: string;
}

const ValidationFindings: React.FC<ValidationFindingsProps> = (
  props: ValidationFindingsProps
) => {
  const { selectedTenant, selectedEmsType, validationsRunning } =
    useContext(TenantContext);

  // eslint-disable-next-line
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [selectedQuery, setselectedQuery] = useState<string>('');
  const [selectedColumns, setSelectedColumns] = useState<Columns[]>([]);

  const download = (path: string) => {
    window.open(path);
  };

  const viewData = async (query: string) => {
    if (query === selectedQuery) {
      setselectedQuery('');
      return;
    }
    try {
      const params: DownloadQueryViewRequest = {
        tenant: selectedTenant || '',
        emsType: selectedEmsType || '',
        queryType: query,
      };
      setSelectedColumns([]);
      setSelectedData([]);
      setselectedQuery('');
      const res = await downloadDataValidationResult(params);
      const data = processData(res.file);
      setSelectedColumns(data.columns);
      setSelectedData(data.list);
      setselectedQuery(query);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Paper
      style={{
        padding: '1rem',
        marginBottom: '1rem',
        borderRadius: '10px',
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ textAlign: 'left' }}>
        Validation Findings
      </Typography>
      <hr style={{ width: '100%' }} />
      {props.data.length == 0 && !validationsRunning && (
        <Typography variant="h6" gutterBottom>
          No Data
        </Typography>
      )}
      {props.data.length == 0 && validationsRunning && (
        <Typography variant="h6" gutterBottom>
          Validating...
        </Typography>
      )}
      <div className="findings-table">
        <Table>
          <TableBody>
            {props.data.length > 0 &&
              props.data.map((item) => {
                const [name, result] = Object.entries(item)[0];
                return (
                  <TableRow key={name}>
                    {/* <div className="findings-table"> */}
                    <QueryResult
                      expanded={name === selectedQuery}
                      onChange={() => viewData(name)}
                      download={() => download(result)}
                      queryName={name}
                      queryColumns={selectedColumns}
                      queryResults={selectedData}
                    />
                    {/* </div> */}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

const processData = (dataString: string) => {
  const dataStringLines = dataString.split(/\r\n|\n/);
  const headers = dataStringLines[0].split(
    /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
  );

  const list = [];
  for (let i = 1; i < dataStringLines.length; i++) {
    const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
    if (headers && row.length == headers.length) {
      const obj: Record<string, string> = {};
      for (let j = 0; j < headers.length; j++) {
        let d = row[j];
        if (d.length > 0) {
          if (d[0] == '"') d = d.substring(1, d.length - 1);
          if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
        }
        if (headers[j]) obj[headers[j]] = d;
      }
      // remove the blank rows
      if (Object.values(obj).filter((x) => x).length > 0) list.push(obj);
    }
  }
  // prepare columns list from headers
  const columns = headers.map((c) => ({
    name: c,
    accessor: c,
  }));

  return { list: list, columns: columns };
};

export default React.memo(ValidationFindings);
