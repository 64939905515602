import { DDBPollstation, DDBElector } from '../../entities/DynamoObjects';
import { DBAreas } from '../../entities/Pollstation';
import { GetStatsRequest, StatsResponse } from '../../entities/Stats';

import {
  RevertSkippedReqeust,
  GetElectorsRequest,
  MarkElectorRequest,
  ReassignBallotsRequest,
  ReassignBallotsResponse,
  GetElectoralAreasRequest,
  UpdateElectoralAreaRequest,
  GetElectionManagersRequest,
  MoveElectionManagerRequest,
  GetEligibilityRulesRequest,
  UpdateEligibilityRulesRequest,
  GetScrubbedDataRequest,
  DeleteElectionRequest,
  GetBallotRefRequest,
  UpdateBallotRefRequest,
  GetBallotRefResponse,
  GetElectionNameRequest,
  GetElectionNameResponse,
  GetGeographyFileRequest,
  EnableExportAllRequest,
  UpdateFirstLastElectorRequest,
  getFirstLastElectorRequest,
  getFirstLastElectorResponse,
  certificateOfEmploymentElectorRequest,
} from '../../entities/Support';
import { ElectionManager } from '../../entities/User';
import {
  getData,
  postData,
  patchData,
  addQueryParams,
  generateRequest,
  getApiUrl,
  genericRequestHandler,
  deleteData,
} from './rest';
import { EligibilityRules } from '../../entities/EligibilityRules';
import { GeographyFile } from '../../entities/GeographyFile';

export const fetchPollstations = async (
  tenant: string
): Promise<DDBPollstation[]> => {
  const response = await getData('/support/pollstations', { tenant });
  return response;
};

export const revertSkippedBallots = async (
  data: RevertSkippedReqeust
): Promise<void> => {
  const response = await postData('/support/revertskipped', data);
  return response;
};

export const reassignBallots = async (
  data: ReassignBallotsRequest
): Promise<ReassignBallotsResponse> => {
  const response = await postData('/support/reassign-ballot-range', data);
  return response;
};

export const getElectors = async (
  params: GetElectorsRequest
): Promise<DDBElector[]> => {
  const response = await getData('/support/electors', params);
  if (response.results) return response.results;
  if (response.message) throw new Error(response.message);
  return [];
};

export const getClericalAdditions = async (
  params: GetElectorsRequest
): Promise<DDBElector[]> => {
  const response = await getData('/support/clericalAdditions', params);
  if (response.results) return response.results;
  if (response.message) throw new Error(response.message);
  return [];
};

export const getProxyVoters = async (
  params: GetElectorsRequest
): Promise<DDBElector[]> => {
  const response = await getData('/support/proxyVoters', params);
  if (response.results) return response.results;
  if (response.message) throw new Error(response.message);
  return [];
};

export const markElector = async (
  params: MarkElectorRequest
): Promise<void> => {
  const response = await postData('/support/markRegisterForElector', params);
  return response;
};

export const getElectoralAreas = async (
  params: GetElectoralAreasRequest
): Promise<DBAreas> => {
  const response = await getData('/support/getElectoralAreas', params);
  return response;
};

export const updateElectoralArea = async (
  params: UpdateElectoralAreaRequest
): Promise<void> => {
  const response = await postData('/support/updateElectoralArea', params);
  return response;
};

export const getElectionManagers = async (
  params: GetElectionManagersRequest
): Promise<ElectionManager[]> => {
  const response = await getData('/support/getManagers', params);
  return response;
};

export const moveElectionManager = async (
  params: MoveElectionManagerRequest
): Promise<void> => {
  const response = await patchData('/support/moveManager', params);
  return response;
};
// eslint-disable-next-line
export const postcodeUpload = async (filePath = {}): Promise<any> => {
  const uriQuery = addQueryParams('/dashboard/postcodeAnalysis', filePath);
  const request = await generateRequest('GET');
  const response = await fetch(`${getApiUrl()}${uriQuery}`, request);
  return genericRequestHandler(response);
};

export const getEligibilityRules = async (
  params: GetEligibilityRulesRequest
): Promise<EligibilityRules> => {
  // eslint-disable-next-line quotes
  const response = await getData(`/support/getEligibilityRules`, params);
  return response;
};

export const updateEligibilityRules = async (
  params: UpdateEligibilityRulesRequest
): Promise<void> => {
  // eslint-disable-next-line quotes
  const response = await postData(`/support/updateEligibilityRules`, params);
  return response;
};

export const getGeogrpahyFile = async (
  params: GetGeographyFileRequest
): Promise<any> => {
  // eslint-disable-next-line quotes
  const response = await getData(`/support/geography`, params);
  return response;
};

export const updateGeographyFile = async (
  params: GeographyFile,
  tenant: string
): Promise<void> => {
  // eslint-disable-next-line quotes
  const response = await postData(
    `/support/geography?tenant=${tenant.toLocaleLowerCase().trim()}`,
    params
  );
  return response;
};

export const getVoterIncompleteStats = async (
  params: GetStatsRequest
): Promise<StatsResponse[]> => {
  const response = await getData('/support/stats', params);
  console.log(
    `getVoterIncompleteStats > response > ${JSON.stringify(response)}`
  );
  if (response) return response;
  if (response.message) throw new Error(response.message);
  return [];
};

export const getScrubbedDataUrl = async (
  params: GetScrubbedDataRequest
): Promise<string> => {
  const response = await getData(
    `/dashboard/tenants/${params.tenant}/data-anonymise?electionDate=${params.electionDate}`
  );
  console.log(`getScrubbedDataUrl > response > ${JSON.stringify(response)}`);
  if (response) return response;
  if (response.message) throw new Error(response.message);
  return '';
};

export const deleteElection = async (
  params: DeleteElectionRequest
): Promise<string> => {
  const response = await deleteData('/support/deleteElection', params);
  console.log(`deleteElection > response > ${JSON.stringify(response)}`);
  if (response) return response;
  if (response.message) throw new Error(response.message);
  return '';
};

export const getElectionName = async (
  params: GetElectionNameRequest
): Promise<GetElectionNameResponse> => {
  const response = await postData('/support/get-election-name', params);
  console.log(`getElectionName > response > ${JSON.stringify(response)}`);
  if (response) return response;
  if (response.message) throw new Error(response.message);
  return {};
};

export const getBallotRef = async (
  params: GetBallotRefRequest
): Promise<GetBallotRefResponse> => {
  const response = await postData('/support/getBallotRef', params);
  console.log(`getBallotRef > response > ${JSON.stringify(response)}`);
  if (response) return response;
  if (response.message) throw new Error(response.message);
  return {};
};

export const updateBallotRef = async (
  params: UpdateBallotRefRequest
): Promise<void> => {
  const response = await postData('/support/updateBallotRef', params);
  return response;
};

export const enableExportAll = async (
  params: EnableExportAllRequest
): Promise<void> => {
  const response = await postData('/support/enableExportAll', params);
  return response;
};

export const updateFirstLastElector = async (
  params: UpdateFirstLastElectorRequest
): Promise<void> => {
  const response = await postData('/support/updateFirstLastElector', params);
  return response;
};

export const getFirstLastElector = async (
  params: getFirstLastElectorRequest
): Promise<getFirstLastElectorResponse> => {
  const response = await getData('/support/getFirstLastElector', params);
  console.log(`response:`, response);
  if (response) return response[0];
  if (response.message) throw new Error(response.message);
  return { stationnumber: '', firstElector: '', lastElector: '' };
};

export const certificateOfEmploymentElector = async (
  params: certificateOfEmploymentElectorRequest
): Promise<void> => {
  const response = await postData(
    '/support/certificateOfEmploymentElectorRequest',
    params
  );
  return response;
};
