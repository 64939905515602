import React from 'react';
import { Tooltip, Button } from '@mui/material';

export interface RecalculateStatsProps {
  onRecalculateStatsClick: () => void;
  disabled: boolean;
}

const RecalculateStats: React.FC<
  RecalculateStatsProps
  // eslint-disable-next-line no-empty-pattern
> = ({ onRecalculateStatsClick, disabled }: RecalculateStatsProps) => {
  return (
    <div>
      <div style={{ float: 'right' }}>
        <Tooltip title={'Recalculate Election Stats'}>
          <span style={{ marginRight: '0.1rem' }}>
            <Button
              disabled={disabled}
              id="RerunStatsRunBtn"
              color="primary"
              variant="contained"
              onClick={onRecalculateStatsClick}
            >
              Recalculate Stats
            </Button>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default RecalculateStats;
